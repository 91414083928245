@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.share__sms {
  text-decoration: none;
  margin-top: h.rem(2px);

  @include h.breakpoint-m {
    display: none;
  }
}

.share__link-copy {
  border: 0;
  padding: 0;
  background-color: initial;
  font-size: h.rem(20px);
  height: h.rem(20px);
  margin-right: h.rem(19px);
  cursor: default;
  display: none;
  position: relative;

  @include h.breakpoint-m {
    display: block;
  }

  :global(.icon-link) {
    color: var(--share-link-copy-color, --grey-100);
    cursor: pointer;
    text-decoration: none;
  }

  .share__copied-message {
    white-space: nowrap;
    height: var(--share-link--copied--height);
    position: absolute;
    background-color: var(--share-link--copied--bkg-color);
    padding: var(--share-link--copied--padding);
    color: var(--white);
    font-family: var(--share-link--copied--font-family);
    font-style: normal;
    font-weight: var(--share-link--copied--font-weight);
    font-size: var(--share-link--copied--font-size);
    line-height: 150%;
    letter-spacing: var(--share-link--copied--letter-spacing);
    border-radius: h.rem(1px);
    left: 0;
    top: 1.85rem;
    bottom: h.rem(-30px);
    box-shadow: h.rem(1px 2px 2px) rgba(0, 0, 0, 0.25%);
    opacity: 0;
    z-index: 2;
    transition: opacity 200ms linear;
    pointer-events: none;
    cursor: default;

    &.copied {
      opacity: 1;
    }

    &.alignRight {
      right: 0;
      left: unset;
    }

    &.alignCenter {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .icon-alt-check {
    opacity: 0.85;
    font-size: h.rem(24px);
  }

  @include h.breakpoint-s-only {
    font-size: 1rem;
  }
}

.share__copy-area {
  position: absolute;
  top: h.rem(-9999px);
  left: h.rem(-9999px);
}

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.share-list {
  color: var(--share-list-color);

  .share-list__social-list {
    display: flex;
    align-items: center;
    gap: var(--share-list-social-list-gap);
  }

  .share-list__social-icon {
    font-size: var(--share-list-icon-size);
    line-height: h.rem(30px);
  }

  .share-list__social-list-icon a {
    font-size: var(--share-list-icon-size);
  }

  .share-list__social-list-icon {
    max-width: var(--share-list-social-list-icon-max-width);
    margin: var(--share-list-social-list-icon-margin);

    &--first {
      margin: var(--share-list-social-list-icon-first-margin);
    }

    &.social-share-list__icon--twitter {
      width: var(--share-list-social-list-icon-twitter-width);
    }

    a:hover {
      opacity: var(--share-list-hover-opacity);
    }

    :global(.LiveBlogCard) & {
      margin-left: 0;
      margin-right: h.rem(20px);

      ::before {
        font-size: h.rem(20px);
      }
    }
  }

  .share-list__social-menu-expand {
    display: none;
    cursor: pointer;
    width: h.rem(65px);
    height: 100%;
    position: relative;

    @include h.breakpoint-m {
      display: inline-block;
    }

    &:hover {
      opacity: .5;
    }
  }

  &.share-list__is-knockout {
    .share-list__social-list-icon a:hover {
      opacity: 1;
      color: var(--white);
    }
  }

  &.share-list__use-colored-icons {
    .share-list__social-icon,
    .share-list__social-menu-expand {
      color: var(--share-list-use-colored-icons-color);
    }
  }

  &.share-list__is-full-width-mobile {
    max-width: h.rem(300px);

    @include h.breakpoint-m {
      max-width: unset;
    }
  }

  .share-list__social-list-text {
    display: var(--share-list-social-list-text-display);
    font-size: h.rem(12px);
    line-height: h.rem(12px);
    text-transform: var(--share-list-social-list-text-text-transform);
    letter-spacing: var(--share-list-social-list-text-letter-spacing);
    white-space: normal;
    color: var(--share-list-social-list-text-color);

    @include h.breakpoint-m {
      margin-right: h.rem(15px);
      white-space: nowrap;
    }

    :global(.LiveBlogCard) & {
      margin-right: h.rem(4px);
    }
  }

  .share-list__social-menu-dot,
  .share-list__social-menu-dot::before,
  .share-list__social-menu-dot::after {
    position: absolute;
    width: h.rem(5px);
    height: h.rem(5px);
    border-radius: h.rem(5px);
    background-color: currentColor;
  }

  .share-list__social-menu-dot {
    top: h.rem(37px);
    left: h.rem(25px);
    transition: opacity 0.2s 0.6s;

    &::before,
    &::after {
      content: '';
    }

    &::before {
      transform: translateX(-(h.rem(10px)));
      transition: transform .2s 0.6s ease-out;
    }

    &::after {
      transform: translateX(h.rem(10px));
      transition: transform .2s 0.6s ease-out;
    }
  }

  .share-list__social-menu-close {
    position: absolute;
    width: h.rem(20px);
    height: h.rem(20px);
    top: h.rem(29px);
    left: h.rem(17px);
    transition: transform 0.3s 0.4s;
    transform: scaleX(0);
  }

  .share-list__social-menu-close-line {
    stroke: currentColor;
    stroke-width: 1px;
    transform-origin: center;

    &:first-child {
      transform: rotate(135deg);
      transition: transform 0.3s 0.1s;
    }

    &:last-child {
      transform: rotate(45deg);
      transition: transform 0.3s 0.1s;
    }
  }

  .share-list__social-menu-checkbox:checked ~ .share-list__social-menu-expand {
    .socialMenuDot {
      opacity: 0;
      transition: opacity 0.2s 0.2s;
    }

    .share-list__social-menu-dot::before {
      transform: none;
      transition: transform .2s ease-out;
    }

    .share-list__social-menu-dot::after {
      transform: none;
      transition: transform .2s ease-out;
    }

    .share-list__social-menu-close {
      transition: transform 0.1s 0.1s;
      transform: scaleX(1);
    }

    .share-list__social-menu-close-line:first-child {
      transform: rotate(0deg);
      transition: transform 0.15s 0.3s;
    }

    .share-list__social-menu-close-line:last-child {
      transition: transform 0.15s 0.3s;
      transform: rotate(0deg);
    }
  }

  .share-list__social-menu-checkbox {
    ~ .share-list__social-list {
      @include h.breakpoint-m {
        max-width: 165px;
        transform: translateZ(0);
        overflow: hidden;
        transition: max-width 600ms linear;

        // select all icons after second
        li:nth-child(n+4) {
          opacity: 0;
          transition: opacity 400ms linear;
        }
      }
    }

    &:checked ~ .share-list__social-list {
      max-width: 100%;

      li:nth-child(n+4) {
        opacity: 1;
      }
    }
  }

  :global(.LiveBlogCard) & {
    float: left;
  }
}

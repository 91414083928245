@use 'assets/styles/utils/helper' as h;

:root {
  --share-list-icon-size: #{h.rem(24px)};
  --share-list-hover-opacity: 0.7;
  --share-list-color: var(--grey-60);
  --share-list-use-colored-icons-color: var(--default-color);
  --share-list-social-list-text-text-transform: uppercase;
  --share-list-social-list-text-letter-spacing: -0.5px;
  --share-list-social-list-text-color: none;
  --share-list-social-list-text-display: initial;
  --share-list-social-list-icon-margin: #{h.rem(0 12px)};
  --share-list-social-list-icon-first-margin: #{h.rem(0 12px)};
  --share-list-social-list-icon-max-width: #{h.rem(30px)};
  --share-list-social-list-icon-twitter-width: 26px;
  --share-list-social-list-gap: unset;
}

body.news {
  --share-list-color: var(--grey-50);
}

body.think {
  --share-list-color: var(--grey-50);
}

body.better {
  --share-list-color: var(--grey-50);
}

body.deportes {
  --share-list-use-colored-icons-color: var(--blue-60);
  --share-list-color: var(--grey-50);
}

body.noticias {
  --share-list-use-colored-icons-color: var(--blue-60);
  --share-list-color: var(--grey-50);
}

body.select {
  --share-list-color: #999;
  --share-list-social-list-text-color: var(--grey-60);
}

body.today {
  --share-list-color: var(--secondary-color);
  --share-list-social-list-text-display: none;
  --share-list-social-list-icon-twitter-width: 20px;
  --share-list-icon-size: #{h.rem(20px)};
  --share-list-icon-size-s: #{h.rem(20px)};
  --share-list-icon-size-m: #{h.rem(20px)};
  --share-list-social-list-icon-first-margin: #{h.rem(0 12px 0 0)};
}

body.msnbc {
  --share-list-color: var(--grey-50);
}

body.telemundo {
  --share-list-social-list-text-color: var(--grey-60);
}

body.entretenimiento {
  --share-list-social-list-text-color: var(--default-color);
}

body.shows {
  --share-list-social-list-text-color: var(--default-color);
}

@use 'assets/styles/utils/helper' as h;

.share__link-copy {
  --share-link--copied--height: #{h.rem(26px)};
  --share-link--copied--bkg-color: #399e5a;
  --share-link--copied--font-family: var(--founders-mono);
  --share-link--copied--font-size: #{h.rem(14px)};
  --share-link--copied--font-weight: normal;
  --share-link--copied--letter-spacing: -0.04em;
  --share-link--copied--padding: #{h.rem(0 5px 1px 0)};

  :global(body.today) & {
    --share-link--copied--height: #{h.rem(41px)};
    --share-link--copied--bkg-color: var(--secondary-color);
    --share-link--copied--font-family: var(--secondary-font);
    --share-link--copied--font-size: #{h.rem(16px)};
    --share-link--copied--font-weight: 500;
    --share-link--copied--letter-spacing: 0.06em;
    --share-link--copied--padding: #{h.rem(5px 20px 1px 15px)};
  }
}

@use 'assets/styles/utils/helper' as h;

.share-list-container {
  overflow-x: clip;
  position: relative;

  &::after {
    display: block;
    height: 100%;
    width: 30px;
    position: absolute;
    background-color: #fff;
    content: '';
    right: 0;
  }
}

.share-list__wrapper {
  background: #fff;
  height: 36px;
  padding: 0 2px;
  cursor: pointer;
}

.share--card {
  cursor: pointer;
}

.close-icon {
  height: 24px;
  margin: 0 0 0 12px;
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  &:hover {
    opacity: var(--opacity-70);
  }
}

.social-share-button {
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  display: flex;
  align-items: center;
  font-family: var(--oswald-font-family);
  font-size: 13px;
  font-weight: 300;
  line-height: 1;
  position: relative;
  z-index: 1;

  &:hover {
    opacity: var(--opacity-70);
  }
}

.share-list__icons {
  display: flex;
  align-items: center;
  transition: transform 0.35s ease-in-out;
  transform: translateX(150%);
}

.share-list__icons.showSocialShare {
  transform: translateX(0);
}

.share-btn-txt {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  right: 35px;
  display: inline-flex;
  height: 100%;
  align-items: center;
}

.share-btn-txt.showSocialShare {
  opacity: 0;
  right: -35px;
}

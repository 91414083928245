@use 'assets/styles/utils/helper' as h;

:root {
  --lbc--stripes--background: rgba(48, 97, 255, 0.5);
  --lbc--before--border-color: var(--grey-40);
  --lbc--share-link--color: var(--grey-50);

  // shared pullquote and liftout rules
  --lbc--pq-lo--font-size: #{h.rem(20px)};
  --lbc-small--font-size: #{h.rem(16px)};
  --lbc--pq-lo--font-weight: var(--light);
  --lbc--pq-lo--line-height: var(--leading-125);
  --last-body-item-background-gradient: rgba(255, 255, 255, 0);
  --last-body-item-background-gradient-base: rgba(255, 255, 255, 1);
  --lbc--share-link--width: #{h.rem(24px)};
  --lbc--share-link--height: #{h.rem(24px)};
  --card--border-style: dashed;
  --figcaption--margin-top: #{h.rem(16px)};
}

.telemundo,
.noticias,
.deportes,
.entretenimiento,
.shows {
  --lbc--stripes--background: var(--blue-40);
}

.select {
  --lbc--stripes--background: #b9bfc5;
  --lbc--before--border-color: #ccc;
  --lbc--share-link--color: #999;

  // shared pullquote and liftout rules
  --lbc--pq-lo--font-size: #{h.rem(24px)};
  --lbc--pq-lo--font-weight: 900;
  --lbc--pq-lo--line-height: #{h.rem(28.8px)};
}

.today {
  --lbc--stripes--background: var(--orange-30);
  --lbc-small--font-size: #{h.rem(20px)};
  --lbc--share-link--color: var(--secondary-color);
  --lbc--share-link--width: #{h.rem(20px)};
  --lbc--share-link--height: #{h.rem(20px)};
  --card--border-style: solid;
}

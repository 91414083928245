@use 'assets/styles/utils/helper' as h;

.embedCopyTextarea {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.embedFeedback {
  font-family: var(--founders-mono);
  font-weight: normal;
  background-color: var(--white);
  pointer-events: none;
  font-size: h.rem(12px);
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
  top: -20px;
  left: -22px;
  padding: h.rem(10px);
  color: #000;
  height: h.rem(32px);
  width: h.rem(72px);
  transition: opacity 200ms linear;
  opacity: 0;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-top: h.rem(10px) solid;
    border-left: h.rem(10px) solid transparent;
    border-right: h.rem(10px) solid transparent;
    border-top-color: var(--white);
    position: absolute;
    top: h.rem(32px);
    left: h.rem(26px);
  }

  &.embedCopied {
    opacity: 1;
  }
}
